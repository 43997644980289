import React from 'react'
import styled from 'styled-components'
import AppScreensImage from './images/app-screens'
import MainImage from './images/logo-image'
import AdminImage from './images/admin'
import NotifyImage from './images/notify'

const text = {
  title: 'WishList_',
  intro:
    ' came to us to to help them massively improve their customer retention, customer engagement, & inventory management for a unique model within the car industry...',
  sectionOne:
    'With an amazing inventory Wishlist just needed a simple way for customers to browse and request vehicles they wanted. We created that for them & also organized all of their inquries in an easy to use dashboard.',
  sectionTwoTitle: 'WishList iOS & Web Application',
  sectionTwo:
    "Wishlist aims to make car shopping as simple as possible by handling any & all in easy to use apps. Whether you're on your phone or on your computer you can hand off all your car shopping tasks to WishList just by submitting a few details. Wishlist uses technology to quickly find a match.",
  sectionThreeTitle: 'Admin Panel',
  sectionThree:
    'We created a custom admin panel for WishList to track their inventory. We optimized functionality by allowing WishList to sort by customer, make, model, year, mileage, budget, zip code, & inquiry stats. The panel also allows Wishlist to search inventory wide for specifics. We included a notification tab to allow WishList to send messages to its app users and keep customers engaged.',
}

const Landing = () => {
  return (
    <PageContainer>
      <Background>
        <IntroContainer>
          <IntroImageContainer>
            <MainImage />
          </IntroImageContainer>
          <IntroTextContainer>
            <IntroTitle>{text.title}</IntroTitle>
            <IntroDetail>
              <StyledLink>
                Wishlist
              </StyledLink>
              {text.intro}
            </IntroDetail>
          </IntroTextContainer>
        </IntroContainer>
      </Background>
      <Section>
        <TextContainer>{text.sectionOne}</TextContainer>
        <ImageContainer>
          <AppScreensImage />
        </ImageContainer>
      </Section>
      <Section>
        <SectionTitle>{text.sectionTwoTitle}</SectionTitle>
        <TextContainer>{text.sectionTwo}</TextContainer>
        <ImageContainer>
          <AdminImage />
        </ImageContainer>
      </Section>
      <Section>
        <SectionTitle>{text.sectionThreeTitle}</SectionTitle>
        <TextContainer>{text.sectionThree}</TextContainer>
        <ImageContainer>
          <NotifyImage />
        </ImageContainer>
      </Section>
    </PageContainer>
  )
}

export default Landing

const StyledLink = styled.text`
  color: #ea0202;
`

const Background = styled.div`
  background-color: ${props => props.color || 'black'};
  width: 100vw;
  position: relative;
`

const PageContainer = styled.div`
  text-align: center;
  background: white;
  color: black;
  font-family: 'Helvetica Neue';
  line-height: 1.5;
`
const IntroContainer = styled.div`
  background: black;
  color: white;
  display: flex;
  padding: 20px 0px;
  max-width: ${props => props.max || '1200px'};
  margin: 0px auto;

  @media screen and (min-width: 1200px) {
    padding: 50px 0px;
  }
`
const IntroImageContainer = styled.div`
  width: 35%;
  padding-left: 3%;
  padding: 0px 0px 0px 0px;
  margin: auto;
`

const IntroTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
  @media screen and (min-width: 375px) {
    font-size: calc(36px + (100 - 36) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 100px;
  }
`

const IntroDetail = styled.div`
  font-size: 10px;
  padding-right: 10px;
  @media screen and (min-width: 375px) {
    font-size: calc(12px + (28 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`

const IntroTextContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3%;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding || '3%'};
  max-width: ${props => props.max || '1200px'};
  overflow: ${props => props.overflow || 'visible'};
  margin: 0 auto;
  position: relative;
  background: transparent;
`
const TextContainer = styled.div`
  font-size: 12px;
  color: #707070;
  width: 75%;
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 800px;
  padding: 2% 0 3%;
  background-color: transparent;
  z-index: 1;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
const ImageContainer = styled.div`
  width: ${props => props.width || '85%'};
  margin: 0px auto;
`

const SectionTitle = styled.div`
  font-weight: bold;
  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
