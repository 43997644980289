import React from 'react'
import WishlistAutos from '../../components/portfolio/wishlist-autos/index'
import SEO from '../../components/seo'

const WishlistAutosPage = () => (
  <>
    <SEO title="Tabreezi Portfolio" />
    <WishlistAutos />
  </>
)

export default WishlistAutosPage
